<template>
  <form
    class="flow user-card"
    @submit="onSubmit">
    <!-- Current password -->
    <forms-input
      :errors="getErrors('password')"
      @iconClick="() => onChangePasswordVisible('currentPasswordFieldType')"
      :icon-post="currentPasswordFieldIcon"
      :icon-post-interactive="true"
      name="current-password"
      :placeholder="$t('user-portal.user_password_placeholder')"
      ref="current-password"
      required
      :type="currentPasswordFieldType"
      @input="onRemoveError('password')"
      v-model="currentPassword"
    >{{ $t('user-portal.user_current_password') }}</forms-input>

    <!-- New password -->
    <forms-input
      :errors="getErrors('new_password')"
      @iconClick="() => onChangePasswordVisible('newPasswordFieldType')"
      :icon-post="newPasswordFieldIcon"
      :icon-post-interactive="true"
      name="new-password"
      :placeholder="$t('user-portal.user_password_placeholder')"
      ref="new-password"
      required
      :type="newPasswordFieldType"
      @input="onRemoveError('new_password')"
      v-model="newPassword"
    >{{ $t('user-portal.user_password') }}</forms-input>

    <!-- New password confirmation -->
    <forms-input
      :errors="getErrors('new_password_confirmation')"
      @iconClick="onChangePasswordVisible('newPasswordConfirmationFieldType')"
      :icon-post="newPasswordConfirmationFieldIcon"
      :icon-post-interactive="true"
      name="new-password-confirmation"
      :placeholder="$t('user-portal.user_password_confirmation_placeholder')"
      ref="new-password-confirmation"
      required
      :type="newPasswordConfirmationFieldType"
      @input="onRemoveError('new_password_confirmation')"
      v-model="newPasswordConfirmation"
    >{{ $t('user-portal.user_password_confirmation') }}</forms-input>

    <!-- Actions -->
    <footer class="user__actions">
      <actions-button
        :intent="$pepper.Intent.DANGER"
        :disabled="loading"
        :loading="loading"
        class="fill"
        @click="onUpdatePassword"
      >{{ $t('user-portal.action_update_password') }}</actions-button>
    </footer>
  </form>
</template>

<script>
import MixinError from '@/helpers/errors'

export default {
  name: 'FormUpdatePassowrd',

  inject: [ '$user' ],

  mixins: [ MixinError ],

  data() {
    return {
      currentPassword: null,
      currentPasswordFieldType: 'password',

      loading: false,

      newPassword: null,
      newPasswordFieldType: 'password',

      newPasswordConfirmation: null,
      newPasswordConfirmationFieldType: 'password',
    }
  },

  computed: {
    currentPasswordFieldIcon() {
      return this.currentPasswordFieldType == 'password' ? 'visible' : 'hidden'
    },

    newPasswordFieldIcon() {
      return this.newPasswordFieldType == 'password' ? 'visible' : 'hidden'
    },

    newPasswordConfirmationFieldIcon() {
      return this.newPasswordConfirmationFieldType == 'password' ? 'visible' : 'hidden'
    },
  },

  methods: {
    onChangePasswordVisible(field) {
      this[field] = this[field] === 'password' ? 'text' : 'password'
      this.key++
    },

    onSubmit(e) {
      e.preventDefault()
      return
    },

    async onUpdatePassword() {
      this.errors = {}
      this.loading = true

      try {
        await this.$user.updatePassword({ password: this.currentPassword, newPassword: this.newPassword, newPasswordConfirmation: this.newPasswordConfirmation })
        
        this.$notification({
          title: this.$t('user-portal.save_success_title'),
          type: 'success'
        })

        this.reset()
      } catch(e) {
        this.$notification({
          title: this.$t('user-portal.save_error_title'),
          type: 'error'
        })
        this.handleErrors(e)
      } finally {
        this.loading = false
      }
    },

    reset() {
      this.currentPassword = null
      this.newPassword = null
      this.newPasswordConfirmation = null

      this.errors = {}
    }
  },

  mounted() {
    this.reset()
  }
}
</script>