<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h3 class="layout-modal__title">{{ $t('user-portal.forgot_pin_title') }}</h3>
    </template>

    <div 
      class="layout-modal__loader" 
      v-if="loading">
      <ui-loader />
    </div>
    
    <ui-banner
      :intent="$pepper.Intent.WARNING"
      :title="$t('user-portal.reset_pin_needs_logged')"
      v-if="!isLogged">
      <template #description>
        <div class="flow">
          <span class="row">{{ $t('user-portal.reset_pin_needs_logged_description') }}</span>
          
          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            :href="{ name: 'sayl-user-portal.login', query: query }"
          >{{ $t('user-portal.login_action') }}</actions-button>
        </div>
      </template>
    </ui-banner>

    <div 
      class="flow" 
      v-if="!loading && isLogged">
      <forms-input
        :errors="getErrors('emergency_code')"
        :hint="$t('user-portal.emergency_code_hint')"
        :placeholder="$t('user-portal.placeholder')"
        type="password"
        @change="canEditPin"
        v-model="code"
      >{{ $t('user-portal.emergence_code') }}</forms-input>

      <forms-pins
        :errors="pinErrors"
        :disabled="!allowPin"
        :steps="['new_pin', 'new_pin_confirmation']"
        @confirm="onConfirm"
        @input="onPinRemoveError"
        v-model="pins"
      />
    </div>

    <template #footer>
      <actions-button 
        :disabled="loading"
        @click="back"
      >{{ $t('user-portal.close_action') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import MixinErrors from '@/helpers/errors'

import FormsPins from '@/components/forms/pins.vue'
import LayoutModal from '@/components/layouts/modal'
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'ModalForgotPin',

  components: {
    FormsPins,
    LayoutModal,
    UiBanner,
  },

  inject: [
    '$user',
    '$wallet'
  ],

  mixins: [
    MixinErrors,
  ],

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      allowPin: false,
      code: null,
      errors: {},
      loading: false,
      pins: {
        new_pin: [],
        new_pin_confirmation: []
      },

      pinKey: 1,
    }
  },

  computed: {
    attrs() {
      return {
        position: 'middle-center',
        visible: this.visible
      }
    },

    isLogged() {
      return this.$basil.get(this.$user, 'user.isLogged', false)
    },

    listeners() {
      return {
        close: this.back
      }
    },

    pinErrors() {
      let ret = this.pinKey && [
        ...this.getErrors('new_pin'),
        ...this.getErrors('new_pin_confirmation'),
      ]
      return ret
    },

    query() {
      let name = this.$route.name
      let params = this.$route.params
      return { route: encodeURIComponent(name), params: encodeURIComponent(JSON.stringify(params)) }
    }
  },

  methods: {
    back() {
      !this.loading && this.$emit('close')
    },

    canEditPin() {
      this.allowPin = this.code != null && this.code.length > 0
    },

    async onConfirm() {
      try {
        this.loading = true
        let args = { 
          emergency_code: this.code, 
          new_pin: this.pins.new_pin.join(''), 
          new_pin_confirmation: this.pins.new_pin_confirmation.join('')
        }

        await this.$wallet.resetPin(args)
        
        this.$notification({
          title: this.$t('user-portal.reset_pin_success'),
          type: 'success'
        })

        this.$emit('close')
        this.$emit('confirm')
      } catch(e) {
        this.handleErrors(e)
        this.$notification({
          title: this.$t('user-portal.reset_pin_errored'),
          type: 'error'
        })
        this.pins = {
          new_pin: [],
          new_pin_confirmation: []
        }
      } finally {
        this.loading = false
      }
    },
    
    onPinConfirm() {
      if(JSON.stringify(this.pins.new_pin) === JSON.stringify(this.pins.new_pin_confirmation)) {
        this.onConfirm()
        return
      }

      this.errors['new_pin_confirmation'] = ['pin_confirmation_must_match_pin']
      this.pinKey = this.pinKey + 1
    },

    onPinRemoveError() {
      this.onRemoveError('pin')
      this.onRemoveError('pin_confirmation')
      this.pinKey = this.pinKey + 1
    },
  },
}
</script>