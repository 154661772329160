<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h3 class="layout-modal__title">{{ $t('user-portal.update_pin_title') }}</h3>
    </template>

    <div 
      class="layout-modal__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="flow" 
      v-if="!loading">
      <forms-pins
        :errors="pinErrors"
        :steps="['current_pin', 'new_pin', 'new_pin_confirmation']"
        @confirm="onConfirm"
        @input="onPinRemoveError"
        v-model="pins"
      />
    </div>

    <template #footer>
      <actions-button 
        :disabled="loading"
        @click="back"
      >{{ $t('user-portal.close_action') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="layout-module-users__action --centered"
        :intent="$pepper.Intent.DANGER"
        @click="onForgotPinShow"
      >{{ $t('user-portal.forgot_pin_action') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import MixinErrors from '@/helpers/errors'

import FormsPins from '@/components/forms/pins.vue'
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'ModalForgotPin',

  components: {
    FormsPins,
    LayoutModal,
  },

  inject: [ '$wallet' ],

  mixins: [
    MixinErrors,
  ],

  props: {
    form: {
      type: String,
      default: 'update',
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errors: {},
      loading: false,

      pins: {
        current_pin: [],
        new_pin: [],
        new_pin_confirmation: []
      },

      pinKey: 1,
    }
  },

  computed: {
    attrs() {
      return {
        position: 'middle-center',
        visible: this.visible
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    pinErrors() {
      let ret = this.pinKey && [
        ...this.getErrors('current_pin'),
        ...this.getErrors('new_pin'),
        ...this.getErrors('new_pin_confirmation'),
      ]
      return ret
    },
  },

  methods: {
    back() {
      !this.loading && this.$emit('close')
    },

    async onConfirm() {
      try {
        this.loading = true
        let args = { 
          current_pin: this.pins.current_pin.join(''), 
          new_pin: this.pins.new_pin.join(''), 
          new_pin_confirmation: this.pins.new_pin_confirmation.join('')
        }

        await this.$wallet.updatePin(args)
        this.$notification({
          title: this.$t('user-portal.update_pin_success'),
          type: 'success'
        })
        this.$emit('close')
      } catch(e) {
        this.handleErrors(e)
        this.$notification({
          title: this.$t('user-portal.update_pin_errored'),
          type: 'error'
        })
        this.pins = {
          current_pin: [],
          new_pin: [],
          new_pin_confirmation: []
        }
      } finally {
        this.loading = false
      }
    },

    onForgotPinShow() {
      this.$emit('forgot-pin')
    },
    
    onPinRemoveError() {
      this.onRemoveError('pin')
      this.onRemoveError('pin_confirmation')
      this.pinKey = this.pinKey + 1
    },
  },
}
</script>