<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />
        <h1 class="user__title">{{ $t('user-portal.user_profile_title')}}</h1>
      </header>

      <!-- Loading -->
      <div
        v-if="loading"
        class="user__loader">
        <ui-loader />
      </div>

      <!-- Body -->
      <div
        v-if="!loading"
        class="user__body flow">
        <div class="-cols">
          <div class="col">
            <div class="user__avatar avatar">
              <forms-label class="avatar__label">{{ $t('user-portal.user_avatar') }}</forms-label>

              <div
                v-if="user.avatar"
                class="avatar__image">
                <img :src="user.avatar">

                <actions-button
                  :appearance="$pepper.Appearance.LINK"
                  class="center"
                  :intent="$pepper.Intent.DANGER"
                  :size="$pepper.Size.S"
                  @click="onDeleteAvatarClick"
                >{{ $t(avatar.deleting ? 'user-portal.avatar_is_being_deleted' : 'user-portal.action_delete_avatar') }}</actions-button>
              </div>

              <forms-file
                :placeholder="$t('user-portal.user_avatar_placeholder')"
                class="avatar__form"
                accept=".png,.jpeg,.jpg,.webp"
                v-model="avatar.file"
                @input="avatarChanged">
              </forms-file>

              <forms-label
                class="avatar__hint"
                :size="$pepper.Size.S">
                {{ $t('user-portal.user_avatar_hint') }}
              </forms-label>
            </div>
          </div>
          <div class="col">
            <!-- Information -->
            <form
              class="view-user-profile__content flow"
              @submit="onSubmit">
              <!-- Firstname -->
              <forms-input
                autofocus
                :errors="getErrors('firstname')"
                :placeholder="$t('user-portal.user_firstname_placeholder')"
                type="text"
                v-model="edition.firstname"
              >{{ $t('user-portal.user_firstname') }}</forms-input>

              <!-- Lastname -->
              <forms-input
                :errors="getErrors('lastname')"
                type="text"
                :placeholder="$t('user-portal.user_lastname_placeholder')"
                v-model="edition.lastname"
              >{{ $t('user-portal.user_lastname') }}</forms-input>
              
              <!-- Email -->
              <forms-input
                :errors="getErrors('email')"
                type="text"
                :placeholder="$t('user-portal.user_email_placeholder')"
                v-model="edition.email"
              >{{ $t('user-portal.user_email') }}</forms-input>

              <!-- Nickname -->
              <forms-input
                :errors="getErrors('nickname')"
                type="text"
                :placeholder="$t('user-portal.user_nickname_placeholder')"
                v-model="edition.nickname"
              >{{ $t('user-portal.nickname') }}</forms-input>
              
              <!-- Phone -->
              <forms-input
                :errors="getErrors('phone')"
                :placeholder="$t('user-portal.user_phone_number_placeholder')"
                :hint="$t('user-portal.user_phone_number_hint')"
                type="text"
                v-model="edition.phone"
              >{{ $t('user-portal.user_phone_number') }}</forms-input>

              <!-- Birthdate -->
              <forms-input
                :errors="getErrors('birthdate')"
                :hint="birthdateHint"
                type="date"
                v-model="edition.birthdate"
              >{{ $t('user-portal.user_birthdate') }}</forms-input>

              <!-- Newsletter optin -->
              <div class="separator">
                <forms-checkbox
                  :checked="edition.opt_in"
                  v-model="edition.opt_in"
                >{{ $t('user-portal.user_optin') }}</forms-checkbox>
              </div>

              <!-- Actions -->
              <footer class="user__actions">
                <actions-button
                  :appearance="$pepper.Appearance.PRIMARY"
                  class="fill"
                  @click="onUpdateUser"
                >{{ $t('user-portal.action_confirm') }}</actions-button>

                <actions-button
                  :appearance="$pepper.Appearance.LINK"
                  class="center"
                  :intent="$pepper.Intent.DANGER"
                  :size="$pepper.Size.S"
                  @click="onDeleteAccountClick"
                >{{ $t('user-portal.action_delete_account') }}</actions-button>
              </footer>
            </form>
          </div>

          <div class="col flow">
            <actions-button
              class="fill"
              @click.stop.prevent="onUpdatePinShow"
              v-if="user.hasWallet && !walletIsCustodial"
            >{{ $t('user-portal.action_update_pin') }}</actions-button>
            
            <form-update-password />

            <modal-update-pin 
              :visible="showUpdatePin"
              @close="onUpdatePinClose"
              @forgot-pin="onForgotPinShow"
              v-if="showUpdatePin"
            />

            <modal-forgot-pin
              :visible="showForgotPin"
              @confirm="onForgotPinConfirm"
              @close="onForgotPinClose"
              v-if="showForgotPin"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import MixinError from '@/helpers/errors'

import FormUpdatePassword from '@/modules/user/components/forms/password.vue'
import ModalForgotPin from '@/components/modals/forgot-pin.vue'
import ModalUpdatePin from '@/modules/user/components/modals/update-pin.vue'
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

export default {
  name: 'UserProfile',

  components: {
    FormUpdatePassword,
    ModalForgotPin,
    ModalUpdatePin,
    NavigationsBreadcrumb,
  },

  inject: [
    '$user',
    '$loyalty',
    '$localStorage',
  ],

  mixins: [ MixinError ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' }},
        { label:  this.$t('user-portal.user_profile_title'), href: '#' }
      ],
      deleteLoading: false,
      emailPattern: /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      errors: {},
      edition: {},
      loading: false,
      showForgotPin: false,
      showUpdatePin: false,
      avatar: {
        file: null,
        deleting: false
      }
    }
  },

  computed: {
    birthdateHint() {
      if(this.hasLoyalty) {
        let birthdayReward = this.$basil.get(this.$loyalty, 'program.rules.birthdayReward', 0)
        if(birthdayReward != null && birthdayReward > 0) {
          return this.$t('user-portal.user_birthday_reward_loyalty_hint', { value: birthdayReward, symbol: this.$basil.get(this.$loyalty, 'program.points.symbol') })
        }
      }
      return null
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    walletIsCustodial() {
      return this.user.walletIsCustodial ?? false
    }
  },

  methods: {
    async avatarChanged() {
      this.avatar.file.errors = []
      this.avatar.file.loading = true
      const data = new FormData()
      data.append('avatar', this.avatar.file.src)
      try {
        await this.$user.storeAvatar(data)
      } catch (e) {
        this.handleErrors(e)
        this.avatar.file.errors = this.getErrors('avatar')
      } finally {
        this.avatar.file.loading = false
      }
    },

    isFieldEmailValid(){
      let value = this.$basil.get(this.edition, 'email', '') || ''
      let hasValue = value.trim().length > 0
      let isValid = this.emailPattern.test(value)

      if(!hasValue) {
        this.errors['email'] = [this.$t(`user-portal.field_is_required`)]
      }

      if(hasValue && !isValid) {
        this.errors['email'] = [this.$t(`user-portal.email_not_correct`)]
      }

      return hasValue && isValid
    },

    async onConfirmDelete() {
      try {
        this.deleteLoading = true
        await this.$user.anonymize()
        window.location.reload()
      } catch(e) {
        $console.error(e)
      } finally {
        this.deleteLoading = false
      }
    },

    onDeleteAccountClick() {
      this.$confirm({
        title: this.$t('user-portal.delete_account_title'),
        description: this.$t('user-portal.delete_account_description'),
        primaryAction: this.$t('user-portal.confirm'),
        secondaryAction: this.$t('user-portal.cancel'),
        onPrimary: () => this.onConfirmDelete()
      })
    },

    async onDeleteAvatarClick() {
      if (this.avatar.deleting) {
        return
      }
      this.avatar.deleting = true
      try {
        await this.$user.deleteAvatar()
      } catch(e) {
        $console.error(e)
      } finally {
        this.avatar.deleting = false
      }
    },

    onForgotPinClose() {
      this.showForgotPin = false
      this.onUpdatePinShow()
    },

    onForgotPinConfirm() {
      this.showForgotPin = false
      this.showUpdatePin = false
    },

    onForgotPinShow() {
      // this.$emit('forgot-pin')
      this.onUpdatePinClose()
      this.showForgotPin = true
    },

    onUpdatePinShow() {
      this.showUpdatePin = true
    },

    onUpdatePinClose() {
      this.showUpdatePin = false
    },

    async onUpdateUser() {
      this.errors = {}
      this.loading = true

      if(!this.isFieldEmailValid()){
        this.loading = false
        return
      }

      try {
        await this.$user.update({ user: this.edition })
        
        this.$notification({
          title: this.$t('user-portal.save_success_title'),
          type: 'success'
        })
        
        this.reset()
      } catch(e) {
        this.$notification({
          title: this.$t('user-portal.save_error_title'),
          type: 'error'
        })
        
        this.handleErrors(e)
      } finally {
        this.loading = false
      }
    },

    onSubmit(e) {
      e.preventDefault()
      return
    },

    reset() {
      this.edition = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        nickname: this.user.nickname,
        phone: this.user.phone,
        opt_in: this.user.optIn === true ? true : false,
        opt_in_tc: false,
        birthdate: this.user.birthdate,
      }
      
      this.errors = {}
    },
  },

  mounted() {
    this.reset()
  },
}
</script>
